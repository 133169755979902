import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

export default function Home({ imageIndex, setImageIndex, imageList }) {
	const disabled = imageList.includes(null);

	return (
		<Listbox value={imageIndex} onChange={setImageIndex} disabled={disabled}>
			<div className="relative mb-4">
				<Listbox.Button className="relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-1 dark:bg-gray-700 dark:text-white dark:disabled:bg-gray-800 sm:text-sm">
					<span className="block truncate">{disabled ? "Generate A Map First" : `Period ${imageIndex + 1} to ${imageIndex + 2}`}</span>
					<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
						<SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
					</span>
				</Listbox.Button>
				<Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
					<Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-700 sm:text-sm">
						{imageList.map((_, id) => (
							<Listbox.Option key={id} className={({ active }) => `${active ? "dark:text-white" : "text-gray-900 dark:text-gray-300"} relative cursor-default select-none py-2 pl-10 pr-4`} value={id}>
								<>
									<span className={`block truncate ` + (imageIndex == id ? "font-medium" : "font-normal")}>
										Period {id + 1} to {id + 2}
									</span>
									{imageIndex == id ? (
										<span className={"absolute inset-y-0 left-0 flex items-center pl-3 " + (imageIndex == id ? "text-white" : "text-white")}>
											<CheckIcon className="h-5 w-5" aria-hidden="true" />
										</span>
									) : null}
								</>
							</Listbox.Option>
						))}
					</Listbox.Options>
				</Transition>
			</div>
		</Listbox>
	);
}
