import Link from "next/link";
import Credits from "./modals/credits";
import { useState } from "react";
export default function Home() {
	const [showCredits, setShowCredits] = useState(false);
	return (
		<>
			<Credits isOpen={showCredits} setIsOpen={setShowCredits} />
			{/*<footer className="bg-gray-200 dark:bg-black dark:text-gray-400 text-center text-md py-3">
          © 2022 <a href="//jaybots.org" target="_blank" rel="noopener" className="hover:text-gray-100 font-bold">Jaybots</a>.
          Created by Anish &amp; Robert. <span className="hover:text-gray-600 dark:hover:text-gray-100 font-bold" onClick={() => {setShowCredits(true)}}>Additional Info</span>
        </footer>*/}
			<div className="bg-gray-200 dark:bg-black">
				<div className="mx-auto px-4 pt-16 sm:max-w-xl md:max-w-full md:px-24 lg:max-w-screen-xl lg:px-8">
					<div className="row-gap-10 mb-8 grid gap-16 lg:grid-cols-6">
						<div className="md:max-w-md lg:col-span-2">
							<a href="/" aria-label="Go home" title="Company" className="inline-flex items-center">
								<img src="//cdn.jaybots.org/logo/birdtransparent.png" className="h-10 w-10" />
								<span className="ml-2 text-xl font-bold uppercase tracking-wide text-gray-800 dark:text-gray-300">John Jay Map</span>
							</a>
							<div className="mt-4 lg:max-w-sm">
								<p className="text-sm text-gray-800 dark:text-gray-300">
									JJHS Mapping Software is proudly sponsored and supported by the{" "}
									<a href="//jaybots.org" target="_BLANK" className="font-bold hover:underline">
										John Jay Robotics Team
									</a>
									.
								</p>
								<p className="mt-4 text-sm text-gray-800 dark:text-gray-300">
									<span
										className="font-bold hover:text-gray-600 dark:hover:text-gray-100 "
										onClick={() => {
											setShowCredits(true);
										}}
									>
										More Info
									</span>
								</p>
							</div>
						</div>
						<div className="row-gap-8 grid grid-cols-2 gap-5 md:grid-cols-4 lg:col-span-4">
							<div>
								<p className="font-semibold tracking-wide text-gray-800 dark:text-gray-300">Important Links</p>
								<ul className="mt-2 space-y-2">
									<li>
										<a href="/landing" className="text-gray-500 transition-colors duration-300 hover:text-gray-400">
											Route Calculator
										</a>
									</li>
									<li>
										<a href="/schedule" className="text-gray-500 transition-colors duration-300 hover:text-gray-400">
											Schedule Calculator
										</a>
									</li>
									<li>
										<a href="/" className="text-gray-500 transition-colors duration-300 hover:text-gray-400">
											Support
										</a>
									</li>
								</ul>
							</div>
							<div>
								<p className="font-semibold tracking-wide text-gray-800 dark:text-gray-300">Business</p>
								<ul className="mt-2 space-y-2">
									<li>
										<a href="/api/dev" target="_BLANK" className="cursor-not-allowed text-gray-500">
											Coming Soon
										</a>
									</li>
								</ul>
							</div>
							<div>
								<p className="font-semibold tracking-wide text-gray-800 dark:text-gray-300">Learn More</p>
								<ul className="mt-2 space-y-2">
									<li>
										<a href="//jaybots.org/team" target="_BLANK" className="text-gray-500 transition-colors duration-300 hover:text-gray-400">
											About Us
										</a>
									</li>
									<li>
										<a href="//jaybots.org/outreach" target="_BLANK" className="text-gray-500 transition-colors duration-300 hover:text-gray-400">
											Outreach
										</a>
									</li>
									<li>
										<a href="//interest.jaybots.org" target="_BLANK" className="text-gray-500 transition-colors duration-300 hover:text-gray-400">
											Interest Page
										</a>
									</li>
								</ul>
							</div>
							<div>
								<p className="font-semibold tracking-wide text-gray-800 dark:text-gray-300">Legal</p>
								<ul className="mt-2 space-y-2">
									<li>
										<a href="//jaybots.org/privacy" target="_BLANK" className="text-gray-500 transition-colors duration-300 hover:text-gray-400">
											Privacy Policy
										</a>
									</li>
									<li>
										<a href="//jaybots.org/tos" target="_BLANK" className="text-gray-500 transition-colors duration-300 hover:text-gray-400">
											Terms of Service
										</a>
									</li>
									<li>
										<a href="mailto:anish@jaybots.org" target="_BLANK" className="text-gray-500 transition-colors duration-300 hover:text-gray-400">
											Questions?
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="flex flex-col justify-between border-t pt-5 pb-10 sm:flex-row">
						<p className="text-sm text-gray-800 dark:text-gray-300">
							© 2022{" "}
							<a href="//jaybots.org" target="_blank" rel="noopener" className="font-bold hover:text-gray-100">
								Jaybots
							</a>
							. Created by Anish &amp; Robert.
						</p>
						<div className="mt-4 flex items-center space-x-4 sm:mt-0">
							<a href="//twitter.com/roboticsjay" target="_BLANK" className="text-gray-500 transition-colors duration-300 hover:text-gray-300">
								<svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
									<path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
								</svg>
							</a>
							<a href="//instagram.com/johnjayroboticsclub" target="_BLANK" className="text-gray-500 transition-colors duration-300 hover:text-gray-300">
								<svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
									<circle cx="15" cy="15" r="4" />
									<path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
								</svg>
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
