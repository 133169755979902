import { Dialog, Transition, Listbox } from "@headlessui/react";
import { Fragment, useState, useRef } from "react";
export default function Security({ isOpen, setIsOpen }) {
	function closeModal() {
		setIsOpen(false);
	}

	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
							<Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
						</Transition.Child>

						<span className="inline-block h-screen align-middle" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block w-full max-w-xl transform overflow-hidden rounded-2xl bg-gray-100 p-3 text-left align-middle shadow-xl transition-all dark:bg-gray-800 md:my-8 md:p-6">
								<Dialog.Title as="h3" className="text-lg font-medium leading-6 dark:text-white">
									Learn More
								</Dialog.Title>
								<div className="mt-2">
									<p className="text-sm text-gray-500 dark:text-gray-300">
										The JJHS Mapping Software was developed in collaboration with the{" "}
										<a className="font-bold underline hover:text-gray-600 hover:no-underline focus:outline-none dark:hover:text-gray-100" href="//jaybots.org" target="_BLANK">
											John Jay Robotics Club
										</a>
										. For security reasons, you must have a <strong>wcsdny.org</strong> email to access the app.
										<br />
										<br />
										When you click <strong>Sign In With Google</strong>, you will be redirected to <strong>accounts.google.com</strong> where you will be asked to sign in with your Google account.
										Once you have signed in, you will be redirected back to this website. Google will provide us with your unique <strong>Google ID</strong> which will be used to identify you.
										<br />
										<br />
										At no point will your email password be shared with us.{" "}
										<a href="//www.youtube.com/watch?v=c5MOyMZBI20" target="_BLANK" className="font-bold underline hover:text-gray-600 hover:no-underline focus:outline-none dark:hover:text-gray-100">
											Learn More
										</a>
									</p>
								</div>
								<div className="mt-4">
									<button
										onClick={closeModal}
										type="button"
										className="focus-visible:ring-grey-500 inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-700 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
									>
										Close
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
}
