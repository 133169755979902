import { Dialog, Transition, Switch } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
export default function Home({ feedbackStatus, isOpen, setIsOpen, setFeedbackStatus, data }) {
	const [followup, setFollowup] = useState(true);

	async function feedback(event) {
		event.preventDefault();
		if (!event.target.feedback.value) return setFeedbackStatus("Comment Is Required");
		if (event.target.email.value && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event.target.email.value)) return setFeedbackStatus("Invalid Email");
		setFeedbackStatus("Processing");
		const json = await fetch(`${process.env.NEXT_PUBLIC_WEBSITE}/api/feedback`, {
			method: "POST",
			body: JSON.stringify({ feedback: event.target.feedback.value, email: event.target.email.value, followup: followup }),
		}).then((res) => res.json());
		console.log(`DEBUG: ${JSON.stringify(json)}`);
		setTimeout(() => {
			setFeedbackStatus("Thanks for your feedback!");
			event.target.feedback.value = "";
		}, 1000);
	}

	function closeModal() {
		setIsOpen(false);
	}
	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
				<div className="min-h-screen px-4 text-center">
					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="inline-block h-screen align-middle" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className="inline-block w-full max-w-xl transform overflow-hidden rounded-2xl bg-gray-100 p-3 text-left align-middle shadow-xl transition-all dark:bg-gray-800 md:my-8 md:p-6">
							<Dialog.Title as="h3" className="text-lg font-medium leading-6 dark:text-white">
								Share your feedback!
							</Dialog.Title>
							<form onSubmit={feedback}>
								<div className="mt-2">
									<p className="text-sm text-gray-500 dark:text-gray-300">
										Your valuable feedback will help us improve our service.
										<textarea
											id="feedback"
											name="feedback"
											rows={3}
											className="scrollbar-thumb-rounded-md mt-1 block w-full resize-none rounded-md border border-gray-700 shadow-sm scrollbar scrollbar-thumb-gray-200 focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:scrollbar-track-gray-700 dark:scrollbar-thumb-gray-900 sm:text-sm"
											placeholder="I found a bug when trying to find a route between classroom 121 and..."
											defaultValue={""}
											style={{}}
										/>
										<div className="grid grid-cols-6 gap-6">
											<div className="col-span-6 sm:col-span-3">
												<label htmlFor="email-address" className="mt-2 block text-sm font-medium text-gray-500 dark:text-gray-300">
													Email address
												</label>
												<input
													type="text"
													id="email"
													autoComplete="email"
													className="mt-1 block w-full cursor-not-allowed rounded-md border-gray-700 text-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 sm:text-sm"
													value={data._id}
													readOnly
												/>
											</div>
											<div className="col-span-6 sm:col-span-3">
												<label htmlFor="email-address" className="mt-2 block text-sm font-medium text-gray-500 dark:text-gray-300">
													Full Name
												</label>
												<input
													type="text"
													id="email"
													autoComplete="email"
													className="mt-1 block w-full cursor-not-allowed rounded-md border-gray-700 text-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 sm:text-sm"
													value={data.name}
													readOnly
												/>
											</div>
										</div>
										<p className="mt-3 text-center text-sm text-gray-400">
											<div className="flex items-start">
												<div className="flex h-5 items-center">
													<input
														id="comments"
														name="comments"
														type="checkbox"
														checked={followup}
														className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0"
														onChange={() => setFollowup(!followup)}
													/>
												</div>
												<div className="ml-3 text-sm">
													<p className="text-gray-400">I agree to being contacted for a followup if necessary.</p>
												</div>
											</div>
										</p>
									</p>
								</div>
								<div className="mt-3">
									{feedbackStatus !== "Processing" && (
										<>
											<button
												type="submit"
												className="focus-visible:ring-grey-500 inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-700 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
											>
												{feedbackStatus ? feedbackStatus : "Submit Feedback"}
											</button>
										</>
									)}
									{feedbackStatus == "Processing" && (
										<>
											<button
												type="button"
												className="focus-visible:ring-grey-500 inline-flex w-full cursor-wait justify-center rounded-md border border-transparent bg-indigo-700 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
											>
												<svg className="h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
													<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
												</svg>
											</button>
										</>
									)}
									<p className="mt-2 text-center text-sm text-gray-400">
										By submitting this form you agree to our{" "}
										<a href="//jaybots.org/privacy" target="_BLANK" className="text-indigo-500 focus:border-indigo-800 focus:text-indigo-500 focus:underline focus:outline-none">
											Privacy Policy
										</a>
										.
									</p>
								</div>
							</form>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
}
