import { Dialog, Transition, Listbox } from "@headlessui/react";
import { Fragment, useState, useRef } from "react";
export default function Affiliate({ isOpen, setIsOpen }) {
	function closeModal() {
		setIsOpen(false);
	}

	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
							<Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
						</Transition.Child>

						<span className="inline-block h-screen align-middle" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block w-full max-w-xl transform overflow-hidden rounded-2xl bg-gray-800 p-3 text-left align-middle shadow-xl transition-all md:my-8 md:p-6">
								<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-white">
									Affiliate Code Info
								</Dialog.Title>
								<div className="mt-2">
									<p className="text-sm text-gray-300">
										The JJHS Mapping Software is provided by the{" "}
										<a className="font-bold underline hover:text-gray-100 hover:no-underline focus:outline-none" href="//jaybots.org" target="_BLANK">
											John Jay Robotics Team
										</a>
										. For security reasons, you must have an <strong>@wcsdny.org</strong> or <strong>@k12.wcsdny.org</strong> email address to use the software.
										<br />
										<br />
										Alternatively, you may have an <strong>Affiliate</strong> code that will provide you temporary access. If you have with an affiliate code, please write it down incase you get
										logged out unexpecedly.
									</p>
								</div>
								<div className="mt-4">
									<button
										onClick={closeModal}
										type="button"
										className="focus-visible:ring-grey-500 inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-700 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
									>
										Close
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
}
