export default function source() {
	return (
		<>
			<link rel="icon" href="https://cdn.jaybots.org/logo/logo.png" />
			<meta name="theme-color" content="#111827" />
			<meta name="description" content="Find the best way to get between your classes at John Jay Senior High!" />
			<meta name="twitter:card" value="summary" />
			<meta name="twitter:title" content="John Jay Senior High School Map" />
			<meta name="twitter:description" content="Find the best way to get between your classes at John Jay Senior High!" />
			<meta property="twitter:image" content="https://cdn.jaybots.org/logo/logo.png" />
			<meta property="og:title" content="John Jay Senior High School Map" />
			<meta property="og:url" content="https://jjmap.jaybots.org" />
			<meta property="og:description" content="Find the best way to get between your classes at John Jay Senior High!" />
			<meta property="og:image" content="https://cdn.jaybots.org/logo/logo.png" />
			<meta property="og:site_name" value="Sponsored by the John Jay Robotics Team" />
			<meta name="keywords" content="john jay high school map, john jay map"></meta>
			<script async src="https://www.googletagmanager.com/gtag/js?id=G-R8ELN3MDX6"></script>
			<link href="/main.css?updated=10_23" rel="stylesheet" type="text/css" />

			<link rel="apple-touch-icon" href="/apple-touch-icon.png" />
			<link rel="apple-touch-icon" sizes="57x57" href="/apple-touch-icon-57x57.png?updated=v1" />
			<link rel="apple-touch-icon" sizes="72x72" href="/apple-touch-icon-72x72.png?updated=v1" />
			<link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon-76x76.png?updated=v1" />
			<link rel="apple-touch-icon" sizes="114x114" href="/apple-touch-icon-114x114.png?updated=v1" />
			<link rel="apple-touch-icon" sizes="120x120" href="/apple-touch-icon-120x120.png?updated=v1" />
			<link rel="apple-touch-icon" sizes="144x144" href="/apple-touch-icon-144x144.png?updated=v1" />
			<link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon-152x152.png?updated=v1" />
			<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon-180x180.png?updated=v1" />

			<script
				dangerouslySetInnerHTML={{
					__html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-R8ELN3MDX6');`,
				}}
			/>
		</>
	);
}
