import React, { useEffect, useState } from "react";
import Head from "next/head";
import Source from "./source";
import Footer from "./footer";
import SentryError from "./modals/sentryError";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/nextjs";
export default function Home({ setData }) {
	const [errorMsg, setErrorMsg] = useState(false);
	const router = useRouter();

	useEffect(async () => {
		try {
			const data = await fetch(`${process.env.NEXT_PUBLIC_WEBSITE}/api/auth/@me`, { credentials: "include" }).then((res) => res.json());
			if (!data || data.error || !data.access) router.push(`/unauthorized?redirect=${router.asPath}`);
			else setData(data);
		} catch (err) {
			const data = Sentry.captureException(err);
			console.log(`[ERROR]: ${err}`);
			setErrorMsg(data);
		}
	}, []);
	return (
		<div className="">
			<Head>
				<title>Authorization Required</title>
				<Source />
			</Head>
			<div className="flex min-h-screen items-center dark:bg-gray-900">
				<div className="container mx-auto">
					<div className="mx-auto my-10 max-w-md">
						<div className="text-center">
							<h1 className="my-3 text-3xl font-semibold dark:text-gray-200">Authorization Check</h1>
							<p className="text-gray-400">Checking your authorization, this should only take a moment.</p>
						</div>
						<div className="m-7 text-white">
							<button type="button" className="inline-flex w-full cursor-wait justify-center rounded-md bg-indigo-800 px-3 py-3 text-white focus:outline-none ">
								<svg className="h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
									<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>
			<Footer />
			<SentryError msg={errorMsg} setIsOpen={setErrorMsg} />
		</div>
	);
}
