import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Router from "next/router";

export default function Error({ msg, setIsOpen }) {
	function closeModal() {
		setIsOpen(false);
	}
	return (
		<>
			<Transition appear show={msg ? true : false} as={Fragment}>
				<Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={() => {}}>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
							<Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
						</Transition.Child>

						<span className="inline-block h-screen align-middle" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block w-full max-w-xl transform overflow-hidden rounded-2xl bg-gray-100 p-3 text-left align-middle shadow-xl transition-all dark:bg-gray-800 md:my-8 md:p-6">
								<Dialog.Title as="h3" className="text-lg font-medium leading-6 dark:text-white">
									An Error Occurred.
								</Dialog.Title>
								<form>
									<div className="mt-2">
										<p className="text-sm text-gray-500 dark:text-gray-300">
											We're on it! Please try refreshing the page. If the error continues, feel free to shoot us an{" "}
											<a className="underline hover:text-gray-100 hover:no-underline focus:outline-none" href={"mailto:anish@jaybots.org?subject=JJMap%20Error%20ID:%20" + msg}>
												email
											</a>
											! Error ID: <strong>{msg}</strong>.
										</p>
									</div>
									<div className="mt-4">
										<button
											onClick={() => {
												Router.reload(window.location.pathname);
											}}
											type="button"
											className="focus-visible:ring-grey-500 inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-700 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
										>
											Refresh
										</button>
									</div>
								</form>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
}
