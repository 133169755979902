import { Dialog, Transition, Listbox } from "@headlessui/react";
import { Fragment, useState, useRef } from "react";
export default function Home({ isOpen, setIsOpen }) {
	function closeModal() {
		setIsOpen(false);
	}

	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
							<Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
						</Transition.Child>

						<span className="inline-block h-screen align-middle" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block w-full max-w-xl transform overflow-hidden rounded-2xl bg-gray-100 p-3 text-left align-middle shadow-xl transition-all dark:bg-gray-800 md:my-8 md:p-6">
								<Dialog.Title as="h3" className="text-lg font-medium leading-6 dark:text-white">
									👋 Hello There!
								</Dialog.Title>
								<div className="mt-2">
									<p className="text-sm text-gray-500 dark:text-gray-300">
										The JJHS Mapping Software is the culmination of months of hard work by members of the{" "}
										<a className="font-bold underline hover:text-gray-600 hover:no-underline focus:outline-none dark:hover:text-gray-100" href="//jaybots.org" target="_BLANK">
											John Jay Robotics Team
										</a>
										. If you have any feedback, feel free to use the Give Feedback button — we'd love to hear from you! <br />
										<br />
										<a className="font-bold underline hover:text-gray-600 hover:no-underline focus:outline-none dark:hover:text-gray-100" href="//jaybots.org/team" target="_BLANK">
											The Team
										</a>{" "}
										<br />
										Anish A. (Junior) - Website & Backend Developer <br />
										Robert Z. ('22) - Backend Developer <br />
										Silvia B. (Junior) - Map Designer <br />
										Matt L. (Senior) - Map Designer <br />
										<br />
										For support, please email us at{" "}
										<a className="underline hover:text-gray-600 hover:no-underline focus:outline-none dark:hover:text-gray-100" href="mailto:anish@jaybots.org" target="_BLANK">
											anish@jaybots.org
										</a>{" "}
										or{" "}
										<a className="underline hover:text-gray-600 hover:no-underline focus:outline-none dark:hover:text-gray-100" href="mailto:anish.anne@k12.wcsdny.org" target="_BLANK">
											anish.anne@k12.wcsdny.org
										</a>
										.
									</p>
								</div>
								<div className="mt-4">
									<button
										onClick={closeModal}
										type="button"
										className="focus-visible:ring-grey-500 inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-700 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
									>
										Close
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
}
