import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Link from "next/link";
import { useState } from "react";
import { useRouter } from "next/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import the FontAwesomeIcon component
import { faComments, faCalendarAlt } from "@fortawesome/free-regular-svg-icons"; // import the icons you need
export default function Home({ page, openFeedback, data }) {
	const router = useRouter();
	const [logout, setLogout] = useState(false);
	async function startLogout() {
		setLogout(true);
		await fetch(`${process.env.NEXT_PUBLIC_WEBSITE}/api/auth/logout`, { method: "post", credentials: "include" });
		router.push("/");
	}
	return (
		<Disclosure as="nav" className="bg-gray-300 dark:bg-black">
			{({ open }) => (
				<>
					<div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
						<div className="relative flex h-16 items-center justify-between">
							<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
								{/* Mobile menu button*/}
								<Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
									<span className="sr-only">Open main menu</span>
									{open ? <XIcon className="block h-6 w-6" aria-hidden="true" /> : <MenuIcon className="block h-6 w-6" aria-hidden="true" />}
								</Disclosure.Button>
							</div>
							<div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
								<div className="flex flex-shrink-0 items-center">
									<a href="//jaybots.org" target="_BLANK">
										<img className="block h-8 w-auto lg:hidden" src="//cdn.jaybots.org/logo/birdtransparent.png" alt="Workflow" />
										<img className="hidden h-8 w-auto lg:block" src="//cdn.jaybots.org/logo/birdtransparent.png" alt="Workflow" />
									</a>
								</div>
								<div className="hidden sm:ml-6 sm:block">
									<div className="flex space-x-4 text-center">
										<a
											key="Basic Route Calculator"
											href="/landing"
											className={(page == "landing" ? "bg-gray-900 text-white" : "text-gray-700 hover:bg-gray-900 hover:text-white dark:text-gray-300") + " rounded-md px-3 py-2 text-sm font-medium"}
											aria-current={page == "landing" ? "page" : undefined}
										>
											Route Calculator
										</a>
										{data && data.mapgic && data.mapgic.includes("Evacuation Routes") && (
											<>
												<a
													key="Evacuation Route Calculator"
													href="/evacuation"
													className={
														(page == "evacuation" ? "bg-gray-800 text-white" : "text-gray-700 hover:bg-gray-900 hover:text-white dark:text-gray-300") + " rounded-md px-3 py-2 text-sm font-medium"
													}
													aria-current={page == "evacuation" ? "page" : undefined}
												>
													Evacuation Routes
												</a>
											</>
										)}
										<a
											key="Schedule Route Calculator"
											href="/schedule"
											className={(page == "schedule" ? "bg-gray-800 text-white" : "text-gray-700 hover:bg-gray-900 hover:text-white dark:text-gray-300") + " rounded-md px-3 py-2 text-sm font-medium"}
											aria-current={page == "schedule" ? "page" : undefined}
										>
											Schedule Calculator
										</a>
										{openFeedback && (
											<>
												<a key="Give Feedback" onClick={openFeedback} className="rounded-md px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-900 hover:text-white dark:text-gray-300">
													Give Feedback
												</a>
											</>
										)}
										{!logout && (
											<>
												<div className="text-right">
													<Menu as="div" className="relative inline-block text-left">
														<div>
															<Menu.Button className="inline-flex w-full justify-center rounded-md bg-opacity-20 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-900 hover:bg-opacity-30 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 dark:text-gray-300">
																{data.name}
																<ChevronDownIcon className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100" aria-hidden="true" />
															</Menu.Button>
														</div>
														<Transition
															as={Fragment}
															enter="transition ease-out duration-100"
															enterFrom="transform opacity-0 scale-95"
															enterTo="transform opacity-100 scale-100"
															leave="transition ease-in duration-75"
															leaveFrom="transform opacity-100 scale-100"
															leaveTo="transform opacity-0 scale-95"
														>
															<Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-700 rounded-md bg-gray-200 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800">
																<div className="px-1 py-1 ">
																	<Menu.Item>
																		<span class="-mt-2 flex transform items-center p-3 text-sm text-gray-600 transition-colors duration-200 dark:text-gray-700 ">
																			<img class="mx-1 h-9 w-9 flex-shrink-0 rounded-full object-cover" src={data.picture} alt="user avatar" rerPolicy="no-referrer" />
																			<div class="mx-1">
																				<h1 class="text-sm font-semibold text-gray-700 dark:text-gray-200">{data.name}</h1>
																				<p class="w-36 truncate text-sm text-gray-500 dark:text-gray-400">{data._id}</p>
																			</div>
																		</span>
																	</Menu.Item>
																</div>
																<div className="px-1 py-1">
																	<Menu.Item>
																		<a href="#" className={"group flex w-full cursor-not-allowed items-center rounded-md px-2 py-2 text-sm text-gray-400"}>
																			<FontAwesomeIcon icon={faCalendarAlt} className="mx-1 h-5 w-5"></FontAwesomeIcon>
																			<span class="mx-1">Manage Schedule</span>
																		</a>
																	</Menu.Item>
																</div>
																<div className="px-1 py-1">
																	{openFeedback && (
																		<>
																			<Menu.Item>
																				{({ active }) => (
																					<button
																						onClick={openFeedback}
																						className={"group flex w-full items-center rounded-md px-2 py-2 text-sm " + (active ? "bg-black text-white" : "text-gray-700 dark:text-gray-300")}
																					>
																						<FontAwesomeIcon icon={faComments} className="mx-1 h-5 w-5"></FontAwesomeIcon>
																						<span class="mx-1">Give Feedback</span>
																					</button>
																				)}
																			</Menu.Item>
																		</>
																	)}
																	<Menu.Item>
																		{({ active }) => (
																			<a
																				href="mailto:anish@jaybots.org"
																				className={"group flex w-full items-center rounded-md px-2 py-2 text-sm " + (active ? "bg-black text-white" : "text-gray-700 dark:text-gray-300")}
																			>
																				<svg class="mx-1 h-5 w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path
																						d="M12 22C6.47967 21.9939 2.00606 17.5203 2 12V11.8C2.10993 6.30452 6.63459 1.92794 12.1307 2.00087C17.6268 2.07379 22.0337 6.56887 21.9978 12.0653C21.9619 17.5618 17.4966 21.9989 12 22ZM11.984 20H12C16.4167 19.9956 19.9942 16.4127 19.992 11.996C19.9898 7.57928 16.4087 3.99999 11.992 3.99999C7.57528 3.99999 3.99421 7.57928 3.992 11.996C3.98979 16.4127 7.56729 19.9956 11.984 20ZM13 18H11V16H13V18ZM13 15H11C10.9684 13.6977 11.6461 12.4808 12.77 11.822C13.43 11.316 14 10.88 14 9.99999C14 8.89542 13.1046 7.99999 12 7.99999C10.8954 7.99999 10 8.89542 10 9.99999H8V9.90999C8.01608 8.48093 8.79333 7.16899 10.039 6.46839C11.2846 5.76778 12.8094 5.78493 14.039 6.51339C15.2685 7.24184 16.0161 8.57093 16 9.99999C15.9284 11.079 15.3497 12.0602 14.44 12.645C13.6177 13.1612 13.0847 14.0328 13 15Z"
																						fill="currentColor"
																					></path>
																				</svg>
																				<span class="mx-1">Help</span>
																			</a>
																		)}
																	</Menu.Item>
																	<Menu.Item>
																		{({ active }) => (
																			<button
																				onClick={startLogout}
																				className={"group flex w-full items-center rounded-md px-2 py-2 text-sm " + (active ? "bg-black text-white" : "text-gray-700 dark:text-gray-300")}
																			>
																				<svg class="mx-1 h-5 w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path
																						d="M19 21H10C8.89543 21 8 20.1046 8 19V15H10V19H19V5H10V9H8V5C8 3.89543 8.89543 3 10 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21ZM12 16V13H3V11H12V8L17 12L12 16Z"
																						fill="currentColor"
																					></path>
																				</svg>
																				<span class="mx-1">Logout</span>
																			</button>
																		)}
																	</Menu.Item>
																</div>
															</Menu.Items>
														</Transition>
													</Menu>
												</div>
											</>
										)}
										{logout && (
											<>
												<a key="Logout" onClick={startLogout} className="rounded-md px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-900 hover:text-white dark:text-gray-300">
													<span className="inline-flex">
														<svg className="mr-2 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
															<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
															<path
																className="opacity-75"
																fill="currentColor"
																d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
															></path>
														</svg>
														Logging Out
													</span>
												</a>
											</>
										)}
										{/*{(!logout) && <>
                      <a
                        key="Logout"
                        onClick={startLogout}
                        className='text-gray-700 hover:bg-gray-900 hover:text-white px-3 py-2 rounded-md text-sm font-medium'
                      >
                        Logout
                      </a>
                    </>}
                    {(logout) && <>
                      <a
                        key="Logout"
                        onClick={startLogout}
                        className='text-gray-700 hover:bg-gray-900 hover:text-white px-3 py-2 rounded-md text-sm font-medium'
                      >
                        <span className="inline-flex">
                          <svg className="w-5 h-5 text-white animate-spin mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          Logging Out
                        </span>
                      </a>
                    </>}*/}
									</div>
								</div>
							</div>
						</div>
					</div>

					<Disclosure.Panel className="sm:hidden">
						<div className="space-y-1 px-2 pt-2 pb-3 text-center">
							<a
								key="Basic Route Calculator"
								href="/landing"
								className={(page == "landing" ? "bg-gray-800 text-white" : "text-gray-700 hover:bg-gray-700 hover:text-white dark:text-gray-300") + " block rounded-md px-3 py-2 text-base font-medium"}
								aria-current={page == "landing" ? "page" : undefined}
							>
								Route Calculator
							</a>
							{data && data.mapgic && data.mapgic.includes("Evacuation Routes") && (
								<>
									<a
										key="Evacuation Route Calculator"
										href="/evacuation"
										className={
											(page == "evacuation" ? "bg-gray-800 text-white" : "text-gray-700 hover:bg-gray-700 hover:text-white dark:text-gray-300") + " block rounded-md px-3 py-2 text-base font-medium"
										}
										aria-current={page == "evacuation" ? "page" : undefined}
									>
										Evacuation Routes
									</a>
								</>
							)}
							<a
								key="Schedule Route Calculator"
								href="/schedule"
								className={
									(page == "schedule" ? "bg-gray-800 text-white" : "text-gray-700 hover:bg-gray-700 hover:text-white dark:text-gray-300") + " block rounded-md px-3 py-2 text-base font-medium"
								}
								aria-current={page == "schedule" ? "page" : undefined}
							>
								Schedule Calculator
							</a>
							{openFeedback && (
								<>
									<a key="Give Feedback" onClick={openFeedback} className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white dark:text-gray-300">
										Give Feedback
									</a>
								</>
							)}
							{!logout && (
								<>
									<a className="text-center ">
										<Menu as="div" className="relative inline-block text-left">
											<div>
												<Menu.Button className="inline-flex w-full justify-center rounded-md bg-opacity-20 px-4 py-2 text-base font-medium text-gray-700 hover:bg-gray-700 hover:bg-opacity-30 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 dark:text-gray-300">
													{data.name}
													<ChevronDownIcon className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100" aria-hidden="true" />
												</Menu.Button>
											</div>
											<Transition
												as={Fragment}
												enter="transition ease-out duration-100"
												enterFrom="transform opacity-0 scale-95"
												enterTo="transform opacity-100 scale-100"
												leave="transition ease-in duration-75"
												leaveFrom="transform opacity-100 scale-100"
												leaveTo="transform opacity-0 scale-95"
											>
												<Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-700 rounded-md bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
													<div className="px-1 py-1 ">
														<Menu.Item>
															<span class="-mt-2 flex transform items-center p-3 text-sm text-gray-700 transition-colors duration-200 dark:text-gray-300 ">
																<img class="mx-1 h-9 w-9 flex-shrink-0 rounded-full object-cover" src={data.picture} alt="user avatar" rerPolicy="no-referrer" />
																<div class="mx-1">
																	<h1 class="text-sm font-semibold text-gray-700 dark:text-gray-200">{data.name}</h1>
																	<p class="w-36 truncate text-sm text-gray-500 dark:text-gray-400">{data._id}</p>
																</div>
															</span>
														</Menu.Item>
													</div>
													<div className="px-1 py-1">
														<Menu.Item>
															<a href="#" className={"group flex w-full cursor-not-allowed items-center rounded-md px-2 py-2 text-sm text-gray-400"}>
																<FontAwesomeIcon icon={faCalendarAlt} className="mx-1 h-5 w-5"></FontAwesomeIcon>
																<span class="mx-1">Manage Schedule</span>
															</a>
														</Menu.Item>
													</div>
													<div className="px-1 py-1">
														{openFeedback && (
															<>
																<Menu.Item>
																	{({ active }) => (
																		<button
																			onClick={openFeedback}
																			className={"group flex w-full items-center rounded-md px-2 py-2 text-sm " + (active ? "bg-black text-white" : "text-gray-700 dark:text-gray-300")}
																		>
																			<FontAwesomeIcon icon={faComments} className="mx-1 h-5 w-5"></FontAwesomeIcon>
																			<span class="mx-1">Give Feedback</span>
																		</button>
																	)}
																</Menu.Item>
															</>
														)}
														<Menu.Item>
															{({ active }) => (
																<a
																	href="mailto:anish@jaybots.org"
																	className={"group flex w-full items-center rounded-md px-2 py-2 text-sm " + (active ? "bg-black text-white" : "text-gray-700 dark:text-gray-300")}
																>
																	<svg class="mx-1 h-5 w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path
																			d="M12 22C6.47967 21.9939 2.00606 17.5203 2 12V11.8C2.10993 6.30452 6.63459 1.92794 12.1307 2.00087C17.6268 2.07379 22.0337 6.56887 21.9978 12.0653C21.9619 17.5618 17.4966 21.9989 12 22ZM11.984 20H12C16.4167 19.9956 19.9942 16.4127 19.992 11.996C19.9898 7.57928 16.4087 3.99999 11.992 3.99999C7.57528 3.99999 3.99421 7.57928 3.992 11.996C3.98979 16.4127 7.56729 19.9956 11.984 20ZM13 18H11V16H13V18ZM13 15H11C10.9684 13.6977 11.6461 12.4808 12.77 11.822C13.43 11.316 14 10.88 14 9.99999C14 8.89542 13.1046 7.99999 12 7.99999C10.8954 7.99999 10 8.89542 10 9.99999H8V9.90999C8.01608 8.48093 8.79333 7.16899 10.039 6.46839C11.2846 5.76778 12.8094 5.78493 14.039 6.51339C15.2685 7.24184 16.0161 8.57093 16 9.99999C15.9284 11.079 15.3497 12.0602 14.44 12.645C13.6177 13.1612 13.0847 14.0328 13 15Z"
																			fill="currentColor"
																		></path>
																	</svg>
																	<span class="mx-1">Help</span>
																</a>
															)}
														</Menu.Item>
														<Menu.Item>
															{({ active }) => (
																<button
																	onClick={startLogout}
																	className={"group flex w-full items-center rounded-md px-2 py-2 text-sm " + (active ? "bg-black text-white" : "text-gray-700 dark:text-gray-300")}
																>
																	<svg class="mx-1 h-5 w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path
																			d="M19 21H10C8.89543 21 8 20.1046 8 19V15H10V19H19V5H10V9H8V5C8 3.89543 8.89543 3 10 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21ZM12 16V13H3V11H12V8L17 12L12 16Z"
																			fill="currentColor"
																		></path>
																	</svg>
																	<span class="mx-1">Logout</span>
																</button>
															)}
														</Menu.Item>
													</div>
												</Menu.Items>
											</Transition>
										</Menu>
									</a>
								</>
							)}
							{logout && (
								<>
									<a key="Logout" onClick={startLogout} className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white">
										<span className="inline-flex">
											<svg className="mr-2 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
												<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
											</svg>
											Logging Out
										</span>
									</a>
								</>
							)}
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
}
