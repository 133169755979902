export default function Home({ id, last, roomCount, setRoomCount }) {
	// data-url={`/api/classrooms/room-${id-1}`}
	return (
		<>
			<div className="col-span-2 my-auto text-right md:col-span-1 lg:col-span-2">
				<label htmlFor="text" className="block text-sm text-gray-600 dark:text-gray-400">
					Period {id}
				</label>
			</div>
			<div className="col-span-6 md:col-span-3 lg:col-span-6">
				<input
					type="text"
					placeholder="Enter a room number."
					name={"room-" + id}
					id={"room-" + id}
					data-visible-properties='["room", "subject"]'
					data-search-by-word="true"
					data-data={`/api/data/all`}
					data-search-in='["room","subject"]'
					data-text-property="{room}"
					data-value-property="id"
					data-min-length="0"
					data-selection-required="true"
					data-relatives={`#room-${id - 1}`}
					data-chained-relatives="true"
					data-search-delay="0"
					data-cache-lifetime="0"
					className="flexdatalist w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-300 focus:border-indigo-300 focus:outline-none focus:ring focus:ring-indigo-100 disabled:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:focus:border-gray-500 dark:focus:ring-gray-900 dark:disabled:bg-gray-800"
				/>
			</div>
			{/*{(last) && <>
        <button type="button" onClick={() => {setRoomCount([...Array(roomCount.length+1)])}} className={"w-full px-3 py-2 text-white rounded-md focus:outline-none bg-indigo-700 hover:bg-indigo-600 pointer-events-auto"}>
          <i class="fas fa-plus-circle"></i>
        </button>
      </>}
      {(!last) && <>
        <button type="button" onClick={() => {setRoomCount([...Array(roomCount.length-1)])}} className={"w-full px-3 py-2 text-white rounded-md focus:outline-none bg-indigo-700 hover:bg-indigo-600 pointer-events-auto"}>
          <i class="fas fa-times-circle"></i>
        </button>
      </>}*/}
		</>
	);
}
