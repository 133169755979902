import { Dialog, Transition, Listbox } from "@headlessui/react";
import { Fragment, useState, useRef } from "react";
export default function Home({ isOpen, setIsOpen, url }) {
	const [copyMsg, setCopyMsg] = useState("Copy Link");
	const textAreaRef = useRef(null);

	function copyToClipboard(e) {
		textAreaRef.current.select();
		document.execCommand("copy");
		e.target.focus();
		setCopyMsg("Copied!");
		setTimeout(() => {
			setCopyMsg("Copy Link Again");
		}, 5000);
	}

	function closeModal() {
		setIsOpen(false);
	}

	function openModal() {
		if (feedbackStatus == "Thanks for your feedback!") setFeedbackStatus(null);
		setIsOpen(true);
	}
	return (
		<>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
							<Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="inline-block h-screen align-middle" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block w-full max-w-xl transform overflow-hidden rounded-2xl bg-gray-100 p-3 text-left align-middle shadow-xl transition-all dark:bg-gray-800 md:my-8 md:p-6">
								<Dialog.Title as="h3" className="text-lg font-medium leading-6 dark:text-white">
									Map Export
								</Dialog.Title>
								<form>
									<div className="mt-2">
										<p className="text-sm text-gray-500 dark:text-gray-300">
											Please follow the steps below to export the map. <br />
											1. Make a copy of <strong></strong>{" "}
											<a
												className="font-bold underline hover:no-underline focus:outline-none"
												href="https://docs.google.com/presentation/d/1FwJlLLaj3TLaMZU0t3OVNBzOt8raQmGE6WoIaVf3d38/copy"
												target="_BLANK"
											>
												this Google Slides template
											</a>
											. <br />
											2. Click the button below to copy the link to the map. <br />
											3. Set the image as the background in the template. <br />
											&nbsp;&nbsp;&nbsp;&nbsp;(Background &gt; Choose Image &gt; By URL) <br />
											4. If necessary, edit the evacuation instructions at the bottom of the slide. <br />
											5. Print the slide / export it as a PDF. <br />
											<input type="text" className="pointer-events-none mt-1 block w-full rounded-md border-gray-700 shadow-sm dark:bg-gray-700 sm:text-sm" value={url} ref={textAreaRef} />
										</p>
									</div>
									<div className="mt-4">
										<button
											onClick={copyToClipboard}
											type="button"
											className="focus-visible:ring-grey-500 inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-700 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
										>
											{copyMsg}
										</button>
									</div>
								</form>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	);
}
